import { Timestamp } from 'firebase-admin/firestore'

import { captureError } from './exceptions'

export function getDateFromFirebaseTimestamp(
  timestamp: Timestamp | Date | string | undefined,
  collectionName?: string,
  docId?: string
) {
  if (!timestamp) {
    return new Date(0)
  }

  if (typeof timestamp === 'string') {
    captureError('FirebaseDateFormatError', {
      error:
        'Parsed date is a string, it should be a firebase timestamp instead.',
      collectionName,
      docId,
    })
    return new Date(timestamp)
  } else if (timestamp instanceof Date) {
    return timestamp
  }

  return new Date(timestamp.seconds * 1000)
}
